<template>
  <div id="app">
    <Contact></Contact>    
  </div>
</template>

<script>
import Contact from "./components/Contact.vue";

export default {
  name: 'App',
  components: {
    Contact
  }
}
</script>

<style>
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #000000;
  padding: 32px;
}
</style>
