import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { name: "Home", path: "/" },
  ]
});

Vue.use(VueGtag, {
  config: { id: "G-W7W3MDRHMC" }
}, router);


new Vue({
  router, 
  render: h => h(App),
}).$mount('#app')
