<template>
  <div>
    Chris Wan is a design technologist. He's good. You should hire him. Say
    <a :class="decrypt_css" id="m" @click.once="decrypt">
    </a>
    <sup v-if="!decrypted_state" @click.once="decrypt">(click to decrypt)</sup>
    <sup v-else>(decrypted)</sup>.
  </div>
</template>

<script>

const cipher = {
  "@": 0, a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9, j: 10, k: 11, l: 12, m: 13, n: 14, o: 15, p: 16, q: 17, r: 18, s: 19, t: 20, u: 21, v: 22, w: 23, x: 24, y: 25, z: 26, ".": 27
}

export default {
  name: "Contact",
  data: function() {
    return {
      decrypted_state: false,
      data: null,
    }
  },
  computed: {
    decrypt_css: function() {
      if (this.decrypted_state) {
        return "de";
      } else {
        return "en";
      }
    }
  },
  mounted: function() {
    const el = this.$el.querySelector("#m");

    el.innerHTML = "vszzanqvdweio@m.s";

    // let email = "hello@chriswan.me".split("");
    // for (let i = 0; i < email.length; i++) {
    //   console.log(email[i], "->", this.to_number(email[i]), "->", ( (this.to_number(email[i])  + 14) % 28), "->", this.to_letter( (this.to_number(email[i])  + 14) % 28));
    //   const r = this.to_letter( (this.to_number(email[i])  + 14) % 28);
    //   email.splice(i, 1, r);
    // }
    // email = email.join("");
    // console.log(email);

  },
  methods: {
    to_number: function(letter) {
      return cipher[letter];// number
    },
    to_letter: function(number) {
      for (const [k, v] of Object.entries(cipher)) {
        if (number == v) {
          return k;
        }
      }
    },
    decrypt: function() {
      const el = this.$el.querySelector("#m");
      const x = "vszzanqvdweio@m.s"
      let y = [];

      for (const c of x) {
        y.push(this.to_number(c));
      }

      console.log(y);

      for (let i = 0; i < y.length; i++) {
        const t = this.to_letter((y[i] + 14) % 28);
        console.log(y[i], "->", t);
        y.splice(i, 1, t);
      }

      y = y.join("");

      el.innerHTML = y;

      setTimeout(() => {

        el.href = "mailto:"+y;
      }, 200);
      this.decrypted_state = true;
    }
  }
}
</script>

<style class="scss">
  div {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    color: #222222;
  }

  a,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: underline;
  }

  .en {
    color: #CCC;
    cursor: help;
  }

  .en:hover {
    color: inherit;
  }

  .de {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    color: transparent;
    background-image: linear-gradient(220.55deg, #8FFF85 0%, #39A0FF 100%);
    background-size: 200% 200%;
    animation: gradient 6s ease infinite;
  }


  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }


  .de {
    color: inherit;
  }

  .char {
    display: inline-block;
    width: min-content;

  }

  .char div {
    display: block;
    width: min-content;
  }

  i {
    display: none;
  }

  sup {
    font-size: 12px;
    vertical-align: middle;
  }
</style>